async function GeoLocalizador() {
    if (!navigator.geolocation) {
        console.error("Geolocalização não é suportada pelo navegador.");
        return null;
    }

    // Retorna uma Promise para lidar com o comportamento assíncrono
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                resolve({ latitude, longitude }); // Retorna a localização
            },
            (error) => {
                console.error("Erro ao obter geolocalização:", error);
                reject(error); // Rejeita a Promise com o erro
            },
            {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0,
            }
        );
    });
}

export default GeoLocalizador;
