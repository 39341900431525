// Função para calcular a distância entre duas coordenadas usando a fórmula de Haversine
function haversine(lat1, lon1, lat2, lon2) {
  const toRadians = (degrees) => (degrees * Math.PI) / 180; // Converter graus para radianos
  const R = 6371; // Raio médio da Terra em quilômetros

  const dLat = toRadians(lat2 - lat1); // Diferença de latitude
  const dLon = toRadians(lon2 - lon1); // Diferença de longitude

  const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let distancia = R * c
  return distancia.toFixed(1); // Distância em quilômetros
}

/*
// Coordenadas dos dois endereços
const lat1 = -23.550520; // Latitude de São Paulo
const lon1 = -46.6333824; // Longitude de São Paulo

const lat2 = -22.906847; // Latitude do Rio de Janeiro
const lon2 = -43.172896; // Longitude do Rio de Janeiro

// Calcula a distância
const distance = haversine(lat1, lon1, lat2, lon2);

console.log(`A distância entre os dois pontos é de ${distance.toFixed(2)} km.`);
*/

export default haversine

