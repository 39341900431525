import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect, useState } from 'react'
import { Analytics } from '@vercel/analytics/react';
import './App.css'
import Nav from './Nav.js'
import Eventos from './Eventos.js'

function App(props){
  const bcoCredenciais = {usuaria: 'Fodinha', senha:'@SistemaMaia'}
  const credenciais = window.localStorage.getItem('x40#') ?? []
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [busca, setBusca] = useState('')
  const [parametrosPesuisa] = useState(['nome'])
  const [categoria, setCategoria] = useState('Festas e Shows')
  const [subcategoria, setsubcategoria] = useState('')
  const subcategorias = []
  const [selectBairro, setSelectBairro] = useState(false)
  const [selectLocal, setSelectLocal] = useState(false)
  const [gratis, setGratis] = useState(false)
  const [selectValor, setSelectValor] = useState(0)
  const [selectData, setSelectData] = useState(new Date().toLocaleString('pt-BR').split(',')[0])
  const [paginaAtual, setPaginaAtual] = useState(1)
  const [localizacao, setLocalizacao] = useState(false)
  const datas = []
  const filtroPesquisa = (lista) => lista.filter((item) => parametrosPesuisa.some((newItem) => {return item[newItem]?.toString()?.toLowerCase()?.indexOf(busca.toLowerCase()) > -1}))
  const filtroCategoria = (lista) => categoria ? lista.filter(elememento => elememento.categoria == categoria) : lista
  const filtroSubcategoria = (lista) => subcategoria ? lista.filter(elememento => elememento.subcategoria.includes(subcategoria)) : lista
  const filtroBairro = (lista) => selectBairro ? lista.filter(elememento => elememento.bairro == selectBairro) : lista
  const filtroLocal = (lista) => selectLocal ? lista.filter(elemento => elemento.local == selectLocal) : lista
  const filtroValor = (lista) => selectValor ? lista.filter(elemento => parseInt(elemento.valor) <= selectValor) : lista
  const filtroData = (lista) => selectData ? lista.filter(elemento => elemento.data.toString() == selectData) : lista
  const rolesFiltrados = filtroPesquisa(filtroSubcategoria(filtroCategoria(filtroBairro(filtroLocal(filtroValor(filtroData(props.eventos)))))))
  rolesFiltrados.map((item) => {
    JSON.parse(item.subcategoria).map((e) => {
      if(subcategorias.filter(elemento => elemento == e)[0] == undefined){subcategorias.push(e)}
    })
  })

  props.eventos.map((item) => {
    if(datas.filter(elemento => elemento == item.data)[0] == undefined){datas.push(item.data)}
  })

  /*const organizadorRoles = (lista) => {
    const roles = []
    lista.map((item) => {
      const data = item.data.split('/')
      const dataClin = `${data[2]}-${data[1]}-${data[0]}`
      roles.push(Object.assign(item, {organizador: new Date(dataClin).getTime()}))
    })
    return roles.sort((a, b) => new Date(parseInt(a.organizador)) - new Date(parseInt(b.organizador)))
  }*/


  useEffect(() => setPaginaAtual(1), [busca, categoria, subcategoria, selectValor, selectData])
  useEffect(() => window.addEventListener('scroll', () => setMenuIsOpen(false)), []);
  useEffect(() => window.scrollTo({ top: 0, behavior: 'smooth' }), [paginaAtual, categoria, subcategoria, selectData, selectValor])

const testeAPI = async () => {
  await fetch('https://sandbox.asaas.com/api/v3/pix/qrCodes/static', {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      access_token: '$aact_YTU5YTE0M2M2N2I4MTliNzk0YTI5N2U5MzdjNWZmNDQ6OjAwMDAwMDAwMDAwMDA0NDE1Nzg6OiRhYWNoX2ZiYmZmZTllLTUxOWQtNDgxOS04ZmU3LWIyOTg2MTAzM2RhMQ=='
    },
    body: JSON.stringify({
      description: 'Teste',
      value: 1,
      expirationDate: '2024-05-20 14:20:50',
      allowsMultiplePayments: false
    })
  }).then(res => {
    return res.json()
  }).then(json => console.log(json)).catch(err => console.error('error:' + err))
}

  return(
    <Router>
      <Routes>
        <Route exact path='/' element={
          <div className='flex flex-col items-center'>
            <Nav
              menuIsOpen={menuIsOpen}
              setMenuIsOpen={setMenuIsOpen}
              roles={props.eventos}
              categoria={categoria}
              setCategoria={setCategoria}
              subcategoria={subcategoria}
              setsubcategoria={setsubcategoria}
              busca={busca}
              setBusca={setBusca}
              eventosFiltrado={filtroPesquisa(filtroCategoria(filtroBairro(filtroLocal(filtroValor(filtroData(props.eventos))))))}
              selectBairro={selectBairro}
              setSelectBairro={setSelectBairro}
              gratis={gratis}
              setGratis={setGratis}
              selectValor={selectValor}
              setSelectValor={setSelectValor}
              selectLocal={selectLocal}
              setSelectLocal={setSelectLocal}
              selectData={selectData}
              setSelectData={setSelectData}
              paginaAtual={paginaAtual}
              setPaginaAtual={setPaginaAtual}
            />

            <h1 name='topo' className='flex py-10 text-5xl font-semibold pt-24'>{categoria}</h1>
            <Eventos
              eventosFiltrado={rolesFiltrados}
              setMenuIsOpen={setMenuIsOpen}
              busca={busca}
              setBusca={setBusca}
              setCategoria={setCategoria}
              subcategoria={subcategoria}
              setsubcategoria={setsubcategoria}
              setGratis={setGratis}
              setSelectValor={setSelectValor}
              setSelectData={setSelectData}
              paginaAtual={paginaAtual}
              setPaginaAtual={setPaginaAtual}
              subcategorias={subcategorias.sort()}
              datas={datas}
              localizacao={localizacao}
              setLocalizacao={setLocalizacao}
            />

            <Analytics />

          </div>
        } />

        <Route exact path='/config' element={
          <div className='w-screen h-screen flex flex-col items-center gap-10 pt-32'>
            <h1 className='text-2xl font-bold'>Configurações</h1>
            <div className=' w-full flex justify-around'>
              <div className='flex flex-col items-center'>
                <h2 className='text-xl font-semibold'>Atualizar Roles</h2>
                <div className='flex flex-col gap-4 p-4'>
                  <a href='http://localhost:5000/rotasEvento/atualizar360' className='border rounded-xl bg-blue-200 hover:bg-blue-500 text-center'>Ticket360</a>
                  <a href='http://localhost:5000/rotasEvento/atualizarsympla' className='border rounded-xl bg-blue-200 hover:bg-blue-500 text-center'>Sympla</a>
                  <a href='http://localhost:5000/rotasEvento/atualizarSampaIngressos' className='border rounded-xl bg-blue-200 hover:bg-blue-500 px-2 text-center'>Sampa Ingressos</a>
                  <button className='border rounded-xl bg-blue-200 hover:bg-blue-500 px-2 text-center' onClick={() => {
                    console.log(testeAPI())
                  }}>Teste API</button>
                </div>
              </div>
              <div className='flex flex-col items-center'>
                <h1 className='text-xl font-semibold'>Limpar Roles antigos</h1>
                <a href='http://localhost:5000/rotasEvento/limparroles' className='border rounded-xl bg-blue-200 hover:bg-blue-500 px-2 my-4 text-center'>Limpar</a>
              </div>
            </div>
          </div>
        } />
      </Routes>
    </Router>
  )
}

export default App;
