import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { lista } from './FazGet.js'
import { useState } from 'react';

const root = ReactDOM.createRoot(document.getElementById('root'))
const eventos = lista('https://frail-dani-sam-maia-174c6623.koyeb.app/rotasEvento/')


root.render(
    <App eventos={eventos} />
)